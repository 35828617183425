<template>
    <div class="form-group">
        <label class="zn-label">{{title}}</label>
        <h6 class="zn-text">{{value}}</h6>
    </div>
</template>
<script>
  export default {
    props: ['title','value'],
  }
</script>
<style scoped>
.zn-label{
    font-size: 12px;
    margin-bottom: 0;
}
.zn-text{
  font-size: 14px;line-height: 25px;text-transform: capitalize;
}
</style>